import * as Yup from 'yup';

export function parseScheme(scheme, lang = 'ru') {
  const model = {};
  const buttons = [];

  scheme?.steps?.forEach((step, index) => {
    buttons.push([]);

    step.elements.forEach((element) => {
      model[element.field] = {
        ...element,
        required: element.isRequired,
        disabled: element.isDisabled,
        fieldType: element.type,
        name: element.field,
        step: index + 1,
      };
    });
    step.buttons.forEach((button) => {
      buttons[index].push(button);
    });
  });

  return { model, buttons };
}

export const applyValidation = (validator, rule) => {
  switch (rule) {
    case 'string':
      return validator.string();
    case 'int':
      return validator.number().integer('Value must be an integer');
    case 'double':
      return validator
        .number()
        .test('is-decimal', 'Value must have exactly 2 decimal places', (value) =>
          (value + '').match(/^(?:\d+|\d+\.\d{2})$/),
        );
    case 'number':
      return validator.number();
    case 'optional':
      return validator.mixed().optional();
    case 'mixed-required':
      return validator.mixed().required('This field is required');
    case 'required':
      return validator.required('This field is required');
    case 'onlyNumbers':
      return validator.matches(/^[0-9]+$/, 'Contract field must contain only numbers');
    case 'onlyLetters':
      return validator.matches(
        /^[A-Za-zА-Яа-я\s]+$/,
        'Place of conclusion must contain only letters',
      );
    default:
      return validator;
  }
};

export const generateValidationSchema = (scheme, step) => {
  const validationObj = {};

  const elements = scheme?.steps?.[step - 1]?.elements;

  elements?.forEach((element) => {
    let validator = Yup;

    console.log('element!', element);


    if (element.supposedInputType) {
      validator = applyValidation(validator, element.supposedInputType);

      if (element.isRequired) {
        validator = applyValidation(validator, 'required');
      }
    } else {
      if (element.isRequired) {
        validator = applyValidation(validator, 'mixed-required');
      } else {
        validator = applyValidation(validator, 'optional');
      }
    }

    validationObj[element.field] = validator;
  });

  return Yup.object().shape(validationObj);
};

export const parseSteps = (scheme) => {
  const stepsArray = (scheme?.steps ?? [])?.map((step) => {
    return step.name;
  });

  return stepsArray;
};
