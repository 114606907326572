const key = 'mock-client';
const keyRegistered = 'mock-client-registered';

export const getEnvMockValue = () => {
  const defaultValue = '';

  const applyValue = (item: string) => {
    return item === 'true';
  };

  const temp = '';
  const value = applyValue(temp?.length ? temp : defaultValue);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return value || localStorage.getItem(key) === 'true';
};

export const getEnvMockVariantValue = () => {
  const defaultValue = '';

  const applyValue = (item: string) => {
    return item === 'true';
  };

  const temp = '';
  const value = applyValue(temp?.length ? temp : defaultValue);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return value || localStorage.getItem(keyRegistered) === 'true';
};

export const getMockClientStatus = () => {
  let status = false;
  if (localStorage.getItem(key) === 'true') {
    status = true;
  }

  if (getEnvMockValue()) {
    status = true;
  }

  return status;
};

export const getMockClientRegistered = () => {
  let status = false;
  if (localStorage.getItem(keyRegistered) === 'true') {
    status = true;
  }

  if (getEnvMockVariantValue()) {
    status = true;
  }

  return status;
};

export const setMockClientStatus = (status: boolean) => {
  localStorage.setItem(key, status ? 'true' : 'false');
};
