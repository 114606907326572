import { AppRoutes } from 'config/routes';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import axios from 'axios';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useGetDocumentsTemplatesQuery } from 'store/api/documents';
import { generateValidationSchema, parseScheme, parseSteps } from 'utils/sheme-parser-v2';
import { getToken } from 'utils/token';
import { VariantOption } from '../header';

export const useNewContract = () => {
  const alert = useAlert();
  const { id: documentId } = useParams();
  const navigate = useNavigate();

  const {
    i18n: { language },
  } = useTranslation();

  const templateId = new URLSearchParams(useLocation().search).get('templateId');

  const path = useLocation().pathname;
  const { Home, Contracts } = AppRoutes.Dashboard;

  const [step, setStep] = useState<number>(1);

  const { data: templates = [] } = useGetDocumentsTemplatesQuery();
  const [currentScheme, setCurrentScheme] = useState<any>(null);
  const [isSchemeLoading, setSchemeLoading] = useState(true);
  const [isSchemeError, setSchemeError] = useState(false);

  const dropDownOptions: VariantOption[] = useMemo(
    () =>
      [
        ...templates.map((item) => ({
          value: item.id,
          label: item.title,
          templateId: item.id,
          path: item.path ?? '',
        })),
      ]?.filter((item) => item),
    [templates],
  );

  const [currentSelect, setCurrentSelect] = useState(dropDownOptions[0]);
  const isApproval = path.includes(AppRoutes.Dashboard.Contracts.ContractApproval);

  const openDocumentSign = (documentId: string) => {
    navigate(
      Home +
        Contracts.Home +
        Contracts.NewContract +
        Contracts.PreviewNewContract +
        `/${documentId}`,
    );
  };

  const getValidationSchema = () => {
    if (isApproval) {
      return Yup.object().shape({});
    }
    return generateValidationSchema(currentScheme, step);
  };

  const createDocument = async (data: any) => {
    try {
      if (currentSelect.path?.length) {
        const response: any = await axios.post(
          `${currentSelect.path}`,
          {
            data,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
            maxRedirects: 5,
          },
        );

        openDocumentSign(response.data?.id);
      }
    } catch (error) {
      alert.error((error as any)?.response?.data?.message?.toString() ?? 'Unknow Error');
      console.error(error);
    }
  };

  useEffect(() => {
    if (templateId) {
      const select = dropDownOptions.find((item) => item.templateId === templateId);
      if (select) {
        setCurrentSelect(select);
      }
    } else {
      setCurrentSelect(dropDownOptions[0]);
    }
  }, [dropDownOptions]);

  useEffect(() => {
    const handle = async () => {
      try {
        if (currentSelect?.path === undefined) {
          return;
        }

        setSchemeLoading(true);
        setSchemeError(false);
        if (currentSelect.path?.length) {
          const endpoint = documentId ? `${documentId}/sign` : 'create';
          const response = await axios.get(`${currentSelect.path}/${endpoint}`, {
            headers: {
              Authorization: `Bearer ${getToken()}`,
              'Accept-Language': language,
            },
            maxRedirects: 5,
          });

          console.log('Response Data:', response.data);
          setCurrentScheme(response.data);
        }
      } catch (error) {
        console.error('error', error);
        setSchemeError(true);
      } finally {
        setSchemeLoading(false);
      }
    };

    handle();
  }, [currentSelect]);

  const { buttons, model, steps } = useMemo(() => {
    return {
      buttons: parseScheme(currentScheme)?.buttons,
      model: parseScheme(currentScheme)?.model,
      steps: parseSteps(currentScheme),
    };
  }, [currentScheme]);

  return {
    step,
    steps,
    model,
    buttons,
    setStep,
    isApproval,
    isSchemeError,
    isSchemeLoading,
    currentSelect,
    dropDownOptions,
    setCurrentSelect,
    createDocument,
    openDocumentSign,
    getValidationSchema,
    currentScheme: currentScheme?.steps,
  };
};
