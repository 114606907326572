import { gql } from 'graphql-request';

export const GET_CORE_DOCUMENTS_TEMPLATES = gql`
  query CoreDocumentTemplates {
    coreDocumentTemplates {
      getPublic {
        id
        typeId
        title
        alias
        description
        path
        createdAt
        updatedAt
        application {
          id
          traits
          alias
        }
      }
    }
  }
`;
