import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMockClientRegistered, getMockClientStatus } from 'utils/mock-client';

export const LoadingCertificates = ({ isLoading }: { isLoading: boolean }) => {
  if (!isLoading || !getMockClientRegistered()) {
    return null;
  }

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 40 }}
    >
      <div style={{ marginBottom: 10 }}>
        <div
          className="spinner"
          style={{
            border: '4px solid rgba(255, 69, 0, 0.1)',
            borderRadius: '50%',
            borderTop: '4px solid #ff4500',
            width: '24px',
            height: '24px',
            animation: 'spin 1s linear infinite',
          }}
        />
        <style>{`
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}</style>
      </div>
      <div style={{ textAlign: 'center' }}>
        Пожалуйста, подождите, пока система подготовит тестовые сертификаты для использования в
        демонстрационном режиме. Обычно эта операция занимает около 30 секунд
      </div>
    </div>
  );
};

export const DemoModeMark = ({ style }: any) => {
  const { t } = useTranslation();
  const [isDemoMode, setIsDemoMode] = useState(false);

  useEffect(() => {
    const isDemoMode = getMockClientStatus();
    setIsDemoMode(!!isDemoMode);
  }, []);

  if (!isDemoMode) {
    return null;
  }

  return (
    <div
      style={{
        borderStyle: 'dashed',
        borderColor: '#ff4500',
        width: 500,
        borderWidth: 1,
        padding: 10,
        color: '#ff4500',
        ...style,
      }}
    >
      {t('Demo Mode Mark')}
    </div>
  );
};
