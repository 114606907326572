import { DatePickerField, InputField } from 'components/common';
import { CheckboxField } from 'components/fields/checkbox-field';
import { FileField } from 'components/fields/file-field/file';
import { SelectField } from 'components/fields/select-fields/select-filed';
import { SwitchField } from 'components/fields/switch-field/select-filed';
import dayjs from 'dayjs';
import { DefaultDateFormat } from 'utils/date';

export const SchemeModificators = {
  required: 'required',
  disabled: 'disabled',
};

export const generateForm = ({ model, step, modificators = [], formValues = {} }) => {
  const filtered = Object.entries(model).filter((values) => {
    const [, item] = values;

    if (step) {
      return item?.step === step;
    }

    return true;
  });

  return filtered?.map((values) => {
    const [, item] = values;

    if (
      Object.keys(item)?.includes('visibleDependency') &&
      !formValues?.[item?.visibleDependency]?.length
    ) {
      return null;
    }

    const modifications = modificators.reduce((acc, modificator) => {
      if (modificator) {
        acc[modificator] = true;
      }

      return acc;
    }, {});

    switch (item.fieldType) {
      case 'input':
        return <InputField {...item} {...modifications} />;
      case 'select':
        return <SelectField {...item} {...modifications} options={item.options} />;
      case 'datepicker':
        return <DatePickerField {...item} {...modifications} />;
      case 'checkbox':
        return <CheckboxField {...item} {...modifications} />;
      case 'switch':
        return <SwitchField {...item} {...modifications} />;
      case 'file':
        return <FileField {...item} {...modifications} />;
      default:
        return null;
    }
  });
};

export const generateInitialValues = (model) => {
  return Object.entries(model).reduce((acc, values) => {
    const [key, item] = values;

    if (item?.value) {
      acc[key] = item.value;

      if (item?.fieldType === 'datepicker' || item?.type === 'datepicker') {
        acc[key] = dayjs(item?.value, 'YYYY-MM-DD').format(DefaultDateFormat);
      }

      if (item?.fieldType === 'switch' || item.type === 'checkbox') {
        acc[key] = item?.value === 'true' ? true : false;
      }
    }

    return acc;
  }, {});
};
