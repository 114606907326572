import cx from 'classnames';
import { HeaderPage } from 'components/header-page/header-page';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button } from 'components/ui/Button';

import { SidenavSteps } from 'components/sidenav-message/steps/steps';

import { DocumentHeaderInfo } from 'components/document-header-info';
import { Loader } from 'components/loader/loader';
import { Formik, FormikValues } from 'formik';
import { useGetCoreDocumentsQuery } from 'store/api/documents';
import { Header } from '../header';

import { useEffect } from 'react';
import { prepareDate } from 'utils/date';
import {
  generateForm,
  generateInitialValues,
  SchemeModificators,
} from 'utils/sheme-parser-v2/generate-form';
import styles from './NewContract.module.scss';
import { useNewContract } from './use-new-contract';

const FormResetter = ({
  resetForm,
  setStep,
  currentSelect,
}: {
  resetForm: () => void;
  setStep: (step: number) => void;
  currentSelect: { value: string; label: string };
}) => {
  useEffect(() => {
    resetForm();
    setStep(1);
  }, [currentSelect]);

  return null;
};

export const NewContract = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const withHeader = true;
  const withFooter = true;

  const {
    step,
    steps,
    model,
    buttons,
    setStep,
    createDocument,
    isApproval,
    currentScheme,
    isSchemeError,
    isSchemeLoading,
    currentSelect,
    setCurrentSelect,
    dropDownOptions,
    openDocumentSign,
    getValidationSchema,
  } = useNewContract();

  const { data: [document] = [], isFetching } = useGetCoreDocumentsQuery(
    { ids: [id ?? ''] },
    { skip: !id },
  );

  const handlePrevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const onSubmit = async (values: FormikValues) => {
    if (step < steps?.length) {
      setStep(step + 1);
      return;
    }

    if (isApproval && id) {
      openDocumentSign(id);
      return;
    }

    const modifiedValues = Object.entries(values).reduce((acc, [key, value]) => {
      if (value !== null && value !== undefined) {
        acc[key] = value;

        if ((model as any)[key].type === 'datepicker') {
          acc[key] = prepareDate(value);
        }

        if ((model as any)[key].type === 'checkbox') {
          acc[key] = value?.toString();
        }
      }
      return acc;
    }, {} as any);

    if (currentSelect.value) {
      console.log('modifiedValues', modifiedValues);
      await createDocument(modifiedValues);
    }
  };

  if (isFetching || isSchemeLoading) {
    return <Loader size="medium" />;
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={getValidationSchema()}
      initialValues={generateInitialValues(model)}
    >
      {({ submitForm, resetForm, validateForm, values, errors }) => (
        <div className={styles['container']}>
          <FormResetter currentSelect={currentSelect} resetForm={resetForm} setStep={setStep} />
          {withHeader && !isApproval && <HeaderPage title={t('New contract')} />}
          {withHeader && isApproval && <DocumentHeaderInfo isLongTitle document={document} />}

          <div className={styles.main}>
            {withHeader && (
              <Header
                disabled={isApproval}
                options={dropDownOptions}
                currentSelect={currentSelect}
                setCurrentSelect={(value) => setCurrentSelect(value ?? dropDownOptions[0])}
              />
            )}
            <div className={styles['content']}>
              <SidenavSteps
                setStep={setStep}
                step={step}
                labels={steps}
                steps={steps?.length}
              />
              <div className={styles['form']}>
                {!isSchemeError &&
                  generateForm({
                    model,
                    step,
                    modificators: isApproval ? [SchemeModificators.disabled] : ([] as any),
                    formValues: values,
                  })}

                {isSchemeError && (
                  <div className={styles['error']}>
                    {t('Something went wrong while loading the form')}
                  </div>
                )}
              </div>
            </div>

            {withFooter && (
              <div className={styles['footer']}>
                {isSchemeError && (
                  <Button className={cx(styles['button'])} onClick={submitForm}>
                    {t('Submit')}
                  </Button>
                )}

                {(Array.isArray(buttons) ? buttons[step - 1] : [])?.map(
                  (button: any, index: number) => {
                    let buttonClassname = null;
                    let buttonHandler = null;

                    switch (button.type) {
                      case 'submit':
                        buttonClassname = cx(styles['button']);
                        buttonHandler = () => {
                          validateForm();
                          submitForm();
                        };
                        break;
                      case 'backward':
                        buttonClassname = cx(styles['button'], styles['button-back']);
                        buttonHandler = handlePrevStep;
                        break;
                      default:
                        buttonClassname = cx(styles['button']);
                        buttonHandler = () => {
                          validateForm();
                          submitForm();
                        };
                    }

                    return (
                      <Button
                        key={index.toString()}
                        className={buttonClassname}
                        onClick={buttonHandler}
                      >
                        {button.label}
                      </Button>
                    );
                  },
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </Formik>
  );
};
