import { certMockBulk, certMockClass, MockCert } from 'api';
import { Certificate, TemplateData } from 'client/models';
import { fromBase64, removeExtraSymbolsFromBase64, toBase64 } from 'helpers';
import TemplateSignerBase from './template-signer-base';
import { generateUser8DigitUUID } from 'client/api/generator';
import { data } from 'jquery';
import { getMockClientRegistered } from 'utils/mock-client';

const TEST_XML_SIGN = `<section style="border-bottom: none; margin-bottom: 0.5rem; color: #3b4395;">
    <div style="border: 3px solid #3b4395; padding: 20px;">
        <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 10px;">
            <div style="border-right: 1px solid #3b4395; padding-right: 5px; align-self: center;">
                <p style="margin: 0; padding: 0; line-height: 150%;">[Company Name]</p>
            </div>
            <div style="padding-left: 10px; align-self: center;">
                <p style="margin: 0; padding: 0; line-height: 150%;">[Last Name]</p>
                <p style="margin: 0; padding: 0; line-height: 150%;">[First Name]</p>
                <p style="margin: 0; padding: 0; line-height: 150%;">[Title]</p>
            </div>
            <div style="border-top: 1px solid #3b4395; padding-top: 10px; grid-column: span 2;">
                <span style="font-weight: 600; text-transform: uppercase; font-size: 0.8rem; margin-bottom: 0.5rem;">Дата и время подписания</span>
                <p style="margin: 0; padding: 0; line-height: 150%;">[Signing Time]</p>
            </div>
            <div style="border-top: 1px solid #3b4395; padding-top: 10px; grid-column: span 2;">
                <span style="font-weight: 600; text-transform: uppercase; font-size: 0.8rem; margin-bottom: 0.5rem;">Сертификат: СЕРИЙНЫЙ НОМЕР, период действия</span>
                <p style="margin: 0; padding: 0; line-height: 150%;">[Serial Number]</p>
                <p style="margin: 0; padding: 0; line-height: 150%;">с [Valid From] по [Valid Until]</p>
            </div>
        </div>
    </div>
</section>`;

const TEST_XML_WRAPPER = `<?xml version="1.0" encoding="utf-8" standalone="no"?>
<?xml-stylesheet type="text/xsl" href="#stylesheet"?>
<!DOCTYPE ON_DOVBB_1_928_00_01_02 [
    <!ELEMENT xsl:stylesheet (#PCDATA)>
    <!ATTLIST xsl:stylesheet id ID #REQUIRED>
]>
<soapenv:Envelope xmlns:ds="http://www.w3.org/2000/09/xmldsig#"
    xmlns:fil="http://ru/ibs/fss/ln/ws/FileOperationsLn.wsdl"
    xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
    xmlns:wsse="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-secext-1.0.xsd"
    xmlns:wsu="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-utility-1.0.xsd">
    <soapenv:Header>
    </soapenv:Header>
    <soapenv:Body>
        <Файл />
        <Visualization wsu:Id="visualization">
          <xsl:stylesheet xmlns:ds="http://www.w3.org/2000/09/xmldsig#"
                xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
                xmlns:wsse="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-secext-1.0.xsd"
                xmlns:xsl="http://www.w3.org/1999/XSL/Transform" id="stylesheet" version="1.0">
                <xsl:output method="html" />
                <xsl:strip-space elements="div h3 h2 p" />

                <xsl:template match="soapenv:Envelope">
                    <html lang="ru">
                        <head>
                            <meta charset="UTF-8" />
                            <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                            <meta content="IE=edge" http-equiv="X-UA-Compatible" />
                        </head>
                        <body>
                           ${TEST_XML_SIGN}
                        </body>
                    </html>
                </xsl:template>
            </xsl:stylesheet>
        </Visualization>
    </soapenv:Body>
</soapenv:Envelope>`;

function processCertificate(cert: any, index: number) {
  if (!cert.pure_pem) return cert;

  return {
    ...cert,
    pure_pem: cert.pure_pem
      .replace(/-----BEGIN CERTIFICATE-----/g, '')
      .replace(/-----END CERTIFICATE-----/g, '')
      .replace(/\n/g, ''),
  };
}

export default class MockSigner extends TemplateSignerBase {
  constructor() {
    super();
  }

  async getCertificates(): Promise<Certificate[]> {
    let savedCertificates = localStorage.getItem('mock-certificates');

    if (!savedCertificates) {
      const isRegisteredRequired = getMockClientRegistered();
      const certificates = (await certMockBulk({ isRegistered: isRegisteredRequired })).Certificates;
      const processedCertificates = Object.values(certificates)
        .filter(Boolean)
        .map((cert, index) => processCertificate(cert, index));

      localStorage.setItem('mock-certificates', JSON.stringify(processedCertificates));
    }

    savedCertificates = JSON.parse(localStorage.getItem('mock-certificates') ?? '[]') ?? [];

    const generatedCertificates = (
      Array.isArray(savedCertificates) ? savedCertificates : []
    ).map((cert: MockCert) => {
      return {
        Thumbprint: cert.thumbprint || '',
        Base64RawData: cert.pure_pem || '',
        ИННЮЛ: cert.attributes.inn_legal_entity || '',
        СНИЛС: cert.attributes.snils,
        ОГРН: cert.attributes.ogrn,
        FriendlyName: cert.attributes.common_name,
        Subject: {
          ИННЮЛ: cert.attributes.inn_legal_entity || '',
          СНИЛС: cert.attributes.snils,
          ОГРН: cert.attributes.ogrn,
          'Имя/Отчество': cert.attributes.given_name,
          Фамилия: cert.attributes.surname ?? '',
          Владелец: cert.attributes.common_name ?? '',
          Компания: cert.attributes.common_name ?? '',

          ИНН: '',
          Город: cert.attributes.locality,
          Регион: cert.attributes.province,
          Улица: cert.attributes.street_address,
          Должность: cert.attributes.position,
        },
      };
    });

    return generatedCertificates as unknown as Certificate[];
  }

  injectTestSignValues = ({
    certificate,
    data,
    info,
  }: {
    data: string;
    certificate: Certificate;
    info?: Partial<TemplateData>;
  }) => {
    return data
      ?.replace('[Company Name]', certificate.Subject['Компания'])
      .replace('[Last Name]', certificate.Subject['Фамилия'])
      .replace('[First Name]', certificate.Subject['Имя/Отчество'])
      .replace('[Title]', certificate.Subject['Должность'])
      .replace('[Signing Time]', info?.validFrom ?? new Date().toISOString())
      .replace('[Serial Number]', `Test-${generateUser8DigitUUID()}`)
      .replace('[Valid From]', info?.validFrom ?? new Date().toISOString())
      .replace('[Valid Until]', info?.validUntil ?? new Date().toISOString());
  };

  injectTestSignToRealDocument = ({
    certificate,
    document,
    info,
  }: {
    document: string;
    certificate: Certificate;
    info?: Partial<TemplateData>;
  }) => {
    return this.injectTestSignValues({
      data: fromBase64(document)?.replace('</body>', `${TEST_XML_SIGN}</body>`),
      certificate,
      info,
    });
  };

  async sign({
    dataForSign,
    certificate,
    props,
  }: {
    dataForSign: string;
    certificate: Certificate;
    props?: Partial<TemplateData>;
  }): Promise<string> {
    const xmlToSign =
      this.injectTestSignToRealDocument({ info: props, document: dataForSign, certificate }) ??
      TEST_XML_WRAPPER;

    return removeExtraSymbolsFromBase64(toBase64(xmlToSign));
  }

  async signXml({
    props,
    dataForSign,
    certificate,
  }: {
    dataForSign: string;
    certificate: Certificate;
    props?: Partial<TemplateData>;
  }): Promise<{ signature: string; detachedSignature: string }> {
    console.log('certificate', certificate);
    const xmlToSign =
      this.injectTestSignToRealDocument({ document: dataForSign, certificate }) ??
      TEST_XML_WRAPPER;

    console.log('xmlToSign', xmlToSign);

    return {
      signature: removeExtraSymbolsFromBase64(toBase64(xmlToSign)),
      detachedSignature: '',
    };
  }
}
